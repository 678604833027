<template>
  <div class="logo-robbu">
    <img src="@/assets/white-label/robbu/images/logo.svg" :style="resolvedStyle">
  </div>
</template>

<script>
export default {
  name: 'VLogo',
  props: {
    height: {
      default: 50
    }
  },
  computed: {
    resolvedStyle() {
      return {
        height: `${this.height}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-robbu {
  img {
    display: block;
  }
}
</style>