<template>
  <div class="avatar" :class="[{border: border}, size]" :style="{'background-image': url ? 'url(' + url + ')' : null}">
    <div v-if="!url && initials.length" class="initials">{{ initials }}</div>
  </div>
</template>

<script>
export default {
  name: 'VAvatar',
  props: {
    border: {
      default: false,
      type: Boolean
    },
    size: {
      default: 'regular',
      type: String
    },
    text: {
      default: null,
      type: String
    },
    url: {
      default: null,
      type: String
    }
  },
  computed: {
    initials() {
      if (!this.text) return '?'

      let words = []

      const generateInitials = (origin) => origin.forEach(word => {
        if (/[a-zA-Z]/.test(word.charAt(0))) words.push(word)
      })

      generateInitials(this.text.split(/, |,|[.]| |-/))

      if (words.length === 1) return words[0].slice(0, 2)

      return words.map(word => word.charAt(0)).slice(0, 2).join('')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.avatar {
  display: flex;
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: rgba($purple, .15);
  border-radius: 50%;
  box-sizing: content-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  .initials {
    display: block;
    color: $purple;
    font-weight: 700;
    text-transform: uppercase;
  }

  &.border {
    border: 2px solid #fff;
  }

  &.small {
    width: 30px;
    height: 30px;

    .initials {
      margin-top: 1px;
      font-size: 12px;
      line-height: 12px;
    }
  }

  &.regular {
    width: 40px;
    height: 40px;

    .initials {
      margin-top: 2px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &.medium {
    width: 35px;
    height: 35px;

    .initials {
      font-size: 13px;
      line-height: 13px;
    }
  }

  &.medium-large {
    width: 45px;
    height: 45px;

    .initials {
      font-size: 15px;
      line-height: 15px;
    }
  }

  &.large {
    width: 50px;
    height: 50px;

    .initials {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
