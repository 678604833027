<template>
  <header>
    <div class="grid-container">
      <div class="grid-x align-middle">
        <div class="cell auto">
          <div class="flex-container align-middle">
            <v-logo class="margin-right-45"/>
            <v-logo-carteiro-digital v-if="showOwnershipLogo" class="margin-top-5" height="28"/>
          </div>
        </div>
        <div class="cell shrink" v-if="userGetter && userGetter.name">
          <v-drop-down>
            <template slot="trigger">
              <v-avatar :text="userGetter.name"/>
            </template>
            <template slot="content">
              <ul>
                <li>
                  <span>{{ userGetter.name }}</span>
                </li>
                <li class="spacer"/>
                <li>
                  <v-link @click.native="logoutUser">Sair</v-link>
                </li>
              </ul>
            </template>
          </v-drop-down>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import VAvatar from "@/components/commons/VAvatar";
import VDropDown from "@/components/commons/VDropDown";
import VLink from "@/components/commons/VLink";
import VLogo from "@/components/logos/VLogo";
import VLogoCarteiroDigital from "@/components/logos/VLogoCarteiroDigital";

export default {
  name: "VHeader",

  components: {
    VAvatar,
    VDropDown,
    VLink,
    VLogo,
    VLogoCarteiroDigital,
  },

  computed: {
    ...mapGetters({
      userGetter: "auth/user",
    }),
    showOwnershipLogo() {
      return this.$whiteLabel.id !== 'robbu'
    }
  },

  methods: {
    ...mapActions({
      clearAuthAction: "auth/clearAuth",
    }),
    logoutUser() {
      this.clearAuthAction();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

header {
  display: flex;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  box-shadow: 0 2px 10px rgba($gray, 0.3);
  align-items: center;
  z-index: 1000;

  .grid-container {
    width: 100%;

    .right {
      display: flex;
      align-items: center;
    }
  }
}
</style>