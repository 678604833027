<template>
  <footer>
    <div class="grid-container">
      <div class="grid-x align-middle">
        <div class="left cell auto">
          <v-text :class="[textColor]" size="super-small">&copy; Todos os direitos reservados {{ currentYear }}</v-text>
          <v-text :class="[textColor]">-</v-text>
          <v-link href="https://robbu.global/docs-category/carteiro-digital" :color="linkColor" size="small">Documentação</v-link>
        </div>
        <div class="cell shrink">
          <a href="https://robbu.global" target="_blank">
            <v-logo-robbu height="24"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ROUTE_HOME_NAME } from "@/support/constants";

import VLink from "@/components/commons/VLink";
import VText from "@/components/commons/VText";
import VLogoRobbu from "@/components/logos/VLogoRobbu";

export default {
  name: "VFooter",
  components: {
    VLink,
    VText,
    VLogoRobbu,
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    textColor() {
      return this.$route.name === ROUTE_HOME_NAME ? "white" :  null
    },
    linkColor() {
      return this.$route.name === ROUTE_HOME_NAME ? "white" : 'gray'
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 30px 0;

  .left{
    display: flex;
    align-items: center;

    .text {
      &.white {
        color: #fff;
      }
    }

    & > *:not(:last-child) {
      margin-right: 15px;
    }
  }
}
</style>