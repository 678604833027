<template>
  <div class="link" :class="[color, size]">
    <router-link v-if="to" :to="to" :target="target">
      <slot/>
    </router-link>
    <a v-else :href="href" :target="target">
      <slot/>
    </a>
  </div>
</template>

<script>
export default {
  name: "VLink",
  props: {
    color: {
      default: 'purple',
      type: String,
    },
    size: {
      default: 'regular',
      type: String,
    },
    href: {
      default: null,
      type: String,
    },
    target: {
      default: null,
      type: String,
    },
    to: {
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.link {
  display: inline-block;
  cursor: pointer;
  transition: 0.4s;

  & > * {
    font-weight: 500;
  }

  &.purple > * {
    color: $purple;
  }

  &.white > * {
    color: #fff;

    &:hover {
      color: $blue;
    }
  }

  &.gray > * {
    color: rgba($gray, 0.8);

    &:hover {
      color: $blue;
    }
  }

  &.regular {
    font-size: 16px;
  }

  &.small {
    font-size: 14px;
  }
}
</style>