<template>
  <div class="button" :class="{loading, disabled}">
    <button :class="[size]" :type="type" :disabled="disabled">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="loading">
          <svg x="0px" y="0px" viewBox="0 0 50 50">
            <path
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      </transition>
      <span class="value">{{ value }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "VButton",
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      default: "regular",
      type: String,
    },
    type: {
      default: "button",
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.button {
  user-select: none;

  button {
    display: block;
    position: relative;
    background: $purple;
    background: linear-gradient(
      135deg,
      rgba($purple, 1) 0%,
      rgba($blue, 1) 100%
    );
    width: 100%;
    cursor: pointer;
    transition: 0.4s;

    .loading {
      position: absolute;
      fill: #fff;
      top: 50%;
      left: 50%;
      margin: -15px 0 0 -15px;

      svg {
        display: block;
        width: 30px;
        height: 30px;
      }
    }

    .value {
      color: #fff;
      font-weight: 700;
      letter-spacing: 1px;
    }

    &:hover {
      box-shadow: 0 0 5px 0 $purple;
    }

    &.regular {
      border-radius: 25px;
      padding: 0 25px;
      height: 50px;
      font-size: 16px;
    }

    &.small {
      border-radius: 20px;
      padding: 0 20px;
      height: 40px;
      font-size: 14px;
    }

    &.tiny {
      border-radius: 12px;
      padding: 0 10px;
      height: 20px;
      font-size: 12px;
    }
  }

  &.loading {
    button {
      .value {
        opacity: 0;
      }
    }
  }

  &.disabled * {
    cursor: not-allowed;
    filter: grayscale(1);
  }
}
</style>