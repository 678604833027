<template>
  <div class="block" :class="classRoot">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VBlock",

  props: {
    background: {
      default: false,
      type: Boolean,
    },
    border: {
      default: true,
      type: Boolean,
    },
    shadow: {
      default: false,
      type: Boolean,
    },
    shadowElevation: {
      default: 2,
      type: Number,
    },
    border: {
      default: true,
      type: Boolean,
    },
  },

  computed: {
    classRoot() {
      return {
        background: this.background,
        border: this.border,
        shadow: this.shadow,
        ['shadow-elevation-'+this.shadowElevation]: this.shadow && this.shadowElevation,
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.block {
  border-radius: 8px;

  &.background {
    background: #fff;
  }

  &.border {
    border: 1px solid $borderColor;
  }

  &.shadow {
    &.shadow-elevation-1 {
      box-shadow: 0 1px 2px rgba($gray, 0.3);
    }
    &.shadow-elevation-2 {
      box-shadow: 0 2px 10px rgba($gray, 0.3);
    }
  }

  &.border {
    border: 1px solid $borderColor;
  }
}
</style>