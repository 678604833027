<template>
  <div class="input-description" :class="{ error }">
    <slot>{{ description }}</slot>
  </div>
</template>

<script>
export default {
  name: "VInputDescription",
  props: {
    description: {
      default: null,
      type: String,
    },
    error: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.input-description {
  color: $borderColor;
  font-size: 12px;
  transition: 0.4s;

  &.error {
    color: $red;
  }
}
</style>