<template>
  <component :is="type" class="title">
    <slot />
  </component>
</template>

<script>
export default {
  name: "PTitle",
  props: {
    type: {
      default: "h1",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.title {
  font-weight: 500;
}

h1 {
  color: $black;
  font-size: 25px;
}

h2 {
  color: darken(#6D6E70, 10%);
  font-size: 22px;
}

h3 {
  color: darken(#6D6E70, 5%);
  font-size: 19px;
}

h4 {
  color: $gray;
  font-size: 16px;
}
</style>