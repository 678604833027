<template>
  <div class="input" :class="{ error, filled: localValue, focused }">
    <div class="content">
      <div v-if="icon" class="icon">
        <span class="material-icons-outlined">{{ icon }}</span>
      </div>
      <label :for="id">{{ placeholder }}</label>
      <input
        :id="id"
        :type="type"
        :value="localValue"
        autofocus="autofocus"
        autocomplete="off"
        :readonly="readOnly"
        ref="input"
        @input="input"
        @focus="focus"
        @blur="blur"
      />
    </div>
    <transition name="fade">
      <div v-if="description" class="description">
        <v-input-description :error="error" :description="description" />
      </div>
    </transition>
  </div>
</template>

<script>
import VInputDescription from "@/components/forms/VInputDescription";

export default {
  name: "VInput",
  props: {
    autofocus: {
      default: false,
      type: Boolean,
    },
    description: {
      default: null,
      type: String,
    },
    error: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    value: {
      default: "text",
    },
  },
  data() {
    return {
      id: `input-${this._uid}`,
      focused: false,
      localValue: this.value,
      readOnly: true,
    };
  },
  methods: {
    blur(event) {
      this.focused = false;
      this.$emit("blur", event.target.value);
    },
    focus(event) {
      this.focused = true;
       this.$emit("focus", event.target.value);
    },
    input(event) {
      this.$emit("input", event.target.value);
    },
  },
  components: {
    VInputDescription,
  },
  watch: {
    value: function (value) {
      this.localValue = value;
    },
  },
  mounted() {
    setTimeout(() => (this.readOnly = false), 500);

    if (this.autofocus) setTimeout(() => this.$refs["input"].focus(), 100);
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.input {
  margin-bottom: 20px;

  .content {
    position: relative;

    .icon {
      position: absolute;
      top: 13px;
      color: $borderColor;
      z-index: 5;
      pointer-events: none;

      & ~ label {
        left: 35px;
      }

      & ~ input {
        padding: 10px 5px 0 35px;
      }
    }

    label {
      position: absolute;
      top: 16px;
      left: 0;
      color: $borderColor;
      font-size: 16px;
      pointer-events: none;
      z-index: 5;
      transition: 0.4s;
    }

    input, .input {
      display: block;
      background: transparent;
      border-bottom: 1px solid $borderColor;
      padding: 10px 0 0 0;
      width: 100%;
      height: 50px;
      color: $black;
      font-size: 16px;
      font-family: $roboto;
      filter: none;
      transition: 0.4s;

      &:-webkit-autofill {
        background-color: #fff !important;
      }

      &:focus {
        border-bottom: 1px solid $purple;
      }
    }
  }

  .description {
    margin-top: 6px;
  }

  &.focused {
    .content {
      .icon {
        color: $purple;
      }
    }
  }

  &.focused,
  &.filled {
    .content {
      label {
        top: 2px;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  &.error {
    .content {
      input {
        border-bottom: 1px solid $red;
      }
    }
  }
}
</style>