<template>
  <div class="drop-down" v-click-outside="hideContent">
    <div class="trigger" :title="triggerTitle" @click.prevent="toggleContent">
      <slot name="trigger">
        <div class="dots-trigger">
          <div v-for="i in 3" :key="i" />
        </div>
      </slot>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-show="showDropdown"
        ref="content"
        class="content"
        @click="itemSelected"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "VDropDown",
  props: {
    hideOnSelect: {
      default: true,
      type: Boolean,
    },
    triggerTitle: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    hideContent() {
      this.showDropdown = false;
    },
    showContent() {
      this.showDropdown = true;
    },
    toggleContent() {
      this.showDropdown ? this.hideContent() : this.showContent();
    },
    itemSelected() {
      if (this.hideOnSelect) {
        this.hideContent();
      }
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.drop-down {
  position: relative;

  .trigger {
    cursor: pointer;

    .dots-trigger {
      display: flex;
      padding: 10px 5px;
      align-items: center;

      div {
        background: $borderColor;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        transition: 0.4s;

        &:not(:last-child) {
          margin-right: 3px;
        }
      }

      &:hover {
        div {
          background: $purple;
        }
      }
    }
  }

  .content {
    position: absolute;
    background: #fff;
    top: 100%;
    right: 0;
    margin-top: 4px;
    border-radius: 6px;
    padding: 5px 0;
    max-height: 280px;
    box-shadow: 0 1px 1px 1px rgba($borderColor, .1), 0 5px 8px 0 rgba($borderColor, .3);
    z-index: 10;

    & /deep/ ul {
      li {
        white-space: nowrap;

        & > * {
          width: 100%;
        }

        a,
        span {
          display: block;
          padding: 15px 20px;
          color: $purple;
          white-space: nowrap;
          transition: 0.4s;
        }

        a {
          &:hover,
          &:focus {
            background: rgba($purple, 0.05);
          }
        }

        &.no-link {
          white-space: nowrap;
        }

        &.spacer {
          background: rgba($borderColor, .1);
          margin: 5px 0;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
}
</style>