<template>
  <div class="layout">
    <v-header />
    <div class="page-content">
      <slot />
    </div>
    <v-footer />
  </div>
</template>

<script>
import VHeader from "@/components/VHeader";
import VFooter from "@/components/VFooter";

export default {
  name: "VBase",
  components: {
    VHeader,
    VFooter,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  padding-top: 90px;

  .page-content {
    padding: 30px 0;
  }
}
</style>