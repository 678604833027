<template>
  <div class="block-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VBlockContent",
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.block-content {
  padding: 25px;

  &:not(:last-child) {
    border-bottom: 1px solid $borderColor;
  }
}
</style>