<template>
  <div
    class="text"
    :class="[
      size,
      { 'line-height': lineHeight, uppercase: uppercase, bold: bold },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "PText",
  props: {
    lineHeight: {
      default: true,
      type: Boolean,
    },
    size: {
      default: "regular",
      type: String,
    },
    bold: {
      default: false,
      type: Boolean,
    },
    uppercase: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.text {
  color: rgba($gray, 0.8);

  &.super-small {
    font-size: 12px;

    &.line-height {
      line-height: 20px;
    }
  }

  &.small {
    font-size: 14px;

    &.line-height {
      line-height: 22px;
    }
  }

  &.regular {
    font-size: 16px;

    &.line-height {
      line-height: 26px;
    }
  }

  &.large {
    font-size: 18px;

    &.line-height {
      line-height: 28px;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.bold {
    font-weight: 500;
  }
}
</style>