<template>
  <div class="logo">
    <svg x="0px" y="0px" viewBox="0 0 95 24" :style="resolvedStyle">
      <path
        d="M8.1,7.1C8,8.2,7.6,9,6.9,9.7c-0.7,0.6-1.6,0.9-2.8,0.9c-1.3,0-2.3-0.4-3-1.3C0.4,8.4,0,7.3,0,5.8V5.2c0-1,0.2-1.8,0.5-2.5C0.8,1.9,1.3,1.4,1.9,1s1.3-0.6,2.2-0.6c1.1,0,2.1,0.3,2.8,0.9C7.6,1.9,8,2.8,8.1,3.9h-2c0-0.6-0.2-1.1-0.5-1.4S4.7,2.1,4.1,2.1c-0.7,0-1.2,0.2-1.5,0.7C2.3,3.3,2.1,4,2.1,5.1v0.7c0,1.1,0.2,1.8,0.5,2.3c0.3,0.5,0.8,0.7,1.5,0.7c0.6,0,1.1-0.1,1.4-0.4C5.8,8.2,6,7.7,6,7.1H8.1z"
      />
      <path
        d="M13.8,10.4c-0.1-0.2-0.2-0.4-0.2-0.7c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.3-0.2-1.8-0.6C9.5,9.5,9.2,9,9.2,8.4c0-0.8,0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.5-0.6h0.9V5.5c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.3,0.4-0.3,0.7h-2c0-0.4,0.1-0.8,0.4-1.2c0.3-0.4,0.6-0.6,1.1-0.9c0.5-0.2,1-0.3,1.6-0.3c0.9,0,1.6,0.2,2.2,0.7s0.8,1.1,0.8,1.9v3.2c0,0.7,0.1,1.2,0.3,1.6v0.1H13.8z M12.2,9.1c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.5-0.5V7.1h-0.7c-1,0-1.5,0.3-1.6,1l0,0.1c0,0.2,0.1,0.4,0.3,0.6C11.6,9,11.9,9.1,12.2,9.1z"
      />
      <path
        d="M21.3,4.9c-0.3,0-0.5-0.1-0.7-0.1c-0.7,0-1.2,0.3-1.5,0.8v4.8h-2V3.1h1.9L19.1,4c0.4-0.7,0.9-1,1.6-1c0.2,0,0.4,0,0.6,0.1L21.3,4.9z"
      />
      <path
        d="M25.2,1.3v1.8h1.3v1.4h-1.3v3.7c0,0.3,0.1,0.5,0.2,0.6C25.5,8.9,25.7,9,25.9,9c0.2,0,0.4,0,0.6,0v1.5c-0.4,0.1-0.8,0.2-1.2,0.2c-1.4,0-2.1-0.7-2.1-2.1v-4h-1.1V3.1h1.1V1.3H25.2z"
      />
      <path
        d="M31.2,10.6c-1.1,0-2-0.3-2.6-1c-0.7-0.7-1-1.5-1-2.6V6.7c0-0.7,0.1-1.4,0.4-2c0.3-0.6,0.7-1,1.2-1.3c0.5-0.3,1.1-0.5,1.8-0.5c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.7v0.8h-4.7c0.1,0.5,0.3,0.9,0.6,1.2C30.4,8.8,30.8,9,31.3,9c0.8,0,1.4-0.3,1.8-0.8l1,1.1c-0.3,0.4-0.7,0.7-1.2,1C32.3,10.5,31.8,10.6,31.2,10.6z M30.9,4.5c-0.4,0-0.7,0.1-1,0.4c-0.2,0.3-0.4,0.6-0.5,1.1h2.7V5.9c0-0.4-0.1-0.8-0.4-1C31.7,4.6,31.3,4.5,30.9,4.5z"
      />
      <path
        d="M35.5,1.2c0-0.3,0.1-0.5,0.3-0.7s0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3C35.6,1.7,35.5,1.5,35.5,1.2z M37.6,10.4h-2V3.1h2V10.4z"
      />
      <path
        d="M43.6,4.9c-0.3,0-0.5-0.1-0.7-0.1c-0.7,0-1.2,0.3-1.5,0.8v4.8h-2V3.1h1.9L41.3,4c0.4-0.7,0.9-1,1.6-1c0.2,0,0.4,0,0.6,0.1L43.6,4.9z"
      />
      <path
        d="M44.2,6.7c0-0.7,0.1-1.4,0.4-1.9c0.3-0.6,0.7-1,1.2-1.3c0.5-0.3,1.1-0.5,1.8-0.5c1,0,1.8,0.3,2.4,0.9c0.6,0.6,1,1.4,1.1,2.5l0,0.5c0,1.1-0.3,2-0.9,2.7c-0.6,0.7-1.5,1-2.5,1c-1.1,0-1.9-0.3-2.5-1C44.5,8.9,44.2,7.9,44.2,6.7L44.2,6.7z M46.1,6.8c0,0.7,0.1,1.2,0.4,1.6C46.8,8.8,47.2,9,47.7,9c0.5,0,0.8-0.2,1.1-0.5c0.3-0.4,0.4-0.9,0.4-1.8c0-0.7-0.1-1.2-0.4-1.6c-0.3-0.4-0.6-0.6-1.1-0.6c-0.5,0-0.9,0.2-1.1,0.6C46.3,5.5,46.1,6,46.1,6.8z"
      />
      <path
        d="M55.9,6.7c0-1.1,0.3-2.1,0.8-2.7c0.5-0.7,1.2-1,2.1-1c0.7,0,1.3,0.3,1.8,0.8V0h2v10.4h-1.8l-0.1-0.8c-0.5,0.6-1.1,0.9-1.9,0.9c-0.9,0-1.6-0.3-2.1-1C56.2,8.9,55.9,7.9,55.9,6.7z M57.9,6.8c0,0.7,0.1,1.2,0.4,1.6c0.2,0.4,0.6,0.6,1,0.6c0.6,0,1-0.3,1.3-0.8V5.3c-0.2-0.5-0.7-0.8-1.3-0.8C58.4,4.5,57.9,5.3,57.9,6.8z"
      />
      <path
        d="M64.3,1.2c0-0.3,0.1-0.5,0.3-0.7s0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3C64.4,1.7,64.3,1.5,64.3,1.2z M66.4,10.4h-2V3.1h2V10.4z"
      />
      <path
        d="M67.9,6.7c0-1.1,0.3-2,0.8-2.7c0.5-0.7,1.3-1,2.2-1c0.8,0,1.4,0.3,1.9,0.8l0.1-0.7h1.8v7.1c0,0.6-0.1,1.2-0.4,1.7c-0.3,0.5-0.7,0.8-1.2,1.1c-0.5,0.2-1.2,0.4-1.9,0.4c-0.5,0-1.1-0.1-1.6-0.3s-0.9-0.5-1.2-0.8l0.9-1.2c0.5,0.5,1.1,0.8,1.8,0.8c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.2V9.8c-0.5,0.5-1.1,0.8-1.8,0.8c-0.9,0-1.6-0.3-2.1-1C68.2,8.8,67.9,7.9,67.9,6.7L67.9,6.7z M69.9,6.8c0,0.7,0.1,1.2,0.4,1.6C70.6,8.8,71,9,71.4,9c0.6,0,1-0.2,1.3-0.7V5.2c-0.3-0.4-0.7-0.7-1.3-0.7c-0.5,0-0.8,0.2-1.1,0.6C70,5.5,69.9,6.1,69.9,6.8z"
      />
      <path
        d="M76.4,1.2c0-0.3,0.1-0.5,0.3-0.7s0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3C76.5,1.7,76.4,1.5,76.4,1.2z M78.5,10.4h-2V3.1h2V10.4z"
      />
      <path
        d="M82.7,1.3v1.8h1.3v1.4h-1.3v3.7c0,0.3,0.1,0.5,0.2,0.6C82.9,8.9,83.1,9,83.4,9c0.2,0,0.4,0,0.6,0v1.5c-0.4,0.1-0.8,0.2-1.2,0.2c-1.4,0-2.1-0.7-2.1-2.1v-4h-1.1V3.1h1.1V1.3H82.7z"
      />
      <path
        d="M89.5,10.4c-0.1-0.2-0.2-0.4-0.2-0.7c-0.5,0.5-1.1,0.8-1.9,0.8c-0.7,0-1.3-0.2-1.8-0.6c-0.5-0.4-0.7-0.9-0.7-1.6c0-0.8,0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.5-0.6h0.9V5.5c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.2S87.1,5,87.1,5.3h-2c0-0.4,0.1-0.8,0.4-1.2c0.3-0.4,0.6-0.6,1.1-0.9c0.5-0.2,1-0.3,1.6-0.3c0.9,0,1.6,0.2,2.2,0.7c0.5,0.5,0.8,1.1,0.8,1.9v3.2c0,0.7,0.1,1.2,0.3,1.6v0.1H89.5z M87.9,9.1c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.5-0.5V7.1h-0.7c-1,0-1.5,0.3-1.6,1l0,0.1c0,0.2,0.1,0.4,0.3,0.6C87.3,9,87.6,9.1,87.9,9.1z"
      />
      <path d="M95,10.4h-2V0h2V10.4z" />
      <path
        d="M0.1,22v-6.6h2.3c0.7,0,1.3,0.1,1.7,0.4c0.4,0.3,0.6,0.7,0.6,1.3c0,0.3-0.1,0.6-0.2,0.8c-0.2,0.2-0.4,0.4-0.7,0.6c0.4,0.1,0.6,0.3,0.8,0.5c0.2,0.3,0.3,0.6,0.3,1c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.5-1.7,0.5H0.1z M1.2,18.2h1.1c0.4,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7s-0.5-0.2-0.9-0.2H1.2V18.2z M1.2,19v2.1h1.3c0.4,0,0.6-0.1,0.8-0.3s0.3-0.4,0.3-0.7c0-0.7-0.4-1-1.1-1.1H1.2z"
      />
      <path
        d="M7.7,20.4l1-3.3h1.2l-2,5.6C7.6,23.6,7.1,24,6.4,24c-0.2,0-0.3,0-0.5-0.1v-0.9l0.2,0c0.3,0,0.5-0.1,0.6-0.2C6.8,22.8,7,22.7,7,22.4L7.2,22l-1.7-4.9h1.2L7.7,20.4z"
      />
      <path
        d="M15.6,19.5h-1.3V22h-1.1v-6.6h2.3c0.8,0,1.4,0.2,1.8,0.5c0.4,0.3,0.6,0.8,0.6,1.5c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l1.5,2.7V22H17L15.6,19.5z M14.4,18.6h1.2c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.9-0.3h-1.2V18.6z"
      />
      <path
        d="M18.8,19.5c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.8-0.9c0.3-0.2,0.7-0.3,1.2-0.3c0.7,0,1.2,0.2,1.6,0.6c0.4,0.4,0.6,1,0.7,1.7l0,0.3c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.7-0.8,0.9c-0.3,0.2-0.7,0.3-1.2,0.3c-0.7,0-1.2-0.2-1.7-0.7S18.8,20.3,18.8,19.5L18.8,19.5z M20,19.6c0,0.5,0.1,0.9,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2s-0.5-0.4-0.9-0.4c-0.4,0-0.6,0.1-0.9,0.4C20.1,18.6,20,19.1,20,19.6z"
      />
      <path
        d="M28.8,19.6c0,0.8-0.2,1.4-0.5,1.8c-0.3,0.4-0.8,0.7-1.4,0.7c-0.6,0-1-0.2-1.3-0.6L25.5,22h-1v-7h1.1v2.5c0.3-0.4,0.7-0.6,1.3-0.6c0.6,0,1.1,0.2,1.4,0.7C28.6,18.1,28.8,18.8,28.8,19.6L28.8,19.6z M27.7,19.5c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.4-0.8-0.4c-0.5,0-0.8,0.2-1,0.6v2c0.2,0.4,0.5,0.6,1,0.6c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-1.2V19.5z"
      />
      <path
        d="M34.2,19.6c0,0.8-0.2,1.4-0.5,1.8c-0.3,0.4-0.8,0.7-1.4,0.7c-0.6,0-1-0.2-1.3-0.6L30.9,22h-1v-7H31v2.5c0.3-0.4,0.7-0.6,1.3-0.6c0.6,0,1.1,0.2,1.4,0.7C34,18.1,34.2,18.8,34.2,19.6L34.2,19.6z M33.1,19.5c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.4-0.8-0.4c-0.5,0-0.8,0.2-1,0.6v2c0.2,0.4,0.5,0.6,1,0.6c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-1.2V19.5z"
      />
      <path
        d="M38.2,21.5c-0.3,0.4-0.8,0.6-1.4,0.6c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.8-0.4-1.3v-3.2h1.1v3.2c0,0.6,0.3,0.9,0.8,0.9c0.5,0,0.9-0.2,1.1-0.6v-3.5h1.1V22h-1L38.2,21.5z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "VLogoCarteiroDigital",
  props: {
    height: {
      default: 24,
    },
  },
  computed: {
    resolvedStyle() {
      return {
        height: `${this.height}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~whiteLabel/scss/variables";

.logo {
  svg {
    display: block;
    fill: $gray;
  }
}
</style>