<template>
  <div class="logo">
    <img src="~whiteLabel/images/logo.svg" :style="resolvedStyle">
  </div>
</template>

<script>
export default {
  name: "VLogo",
  props: {
    height: {
      default: 50
    }
  },
  computed: {
    resolvedStyle() {
      return {
        height: `${this.height}px`,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  img {
    display: block;
  }
}
</style>